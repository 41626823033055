import {logError} from './error.js'

export default {
  assert(...args) {
    this.logIt('assert', args)
  },
  count(...args) {
    this.logIt('count', args)
  },
  dir(...args) {
    this.logIt('dir', args)
  },
  group(...args) {
    this.logIt('group', args)
  },
  groupCollapsed(...args) {
    this.logIt('groupCollapsed', args)
  },
  groupEnd(...args) {
    this.logIt('groupEnd', args)
  },
  info(...args) {
    this.logIt('info', args)
  },
  debug(...args) {
    this.logIt('debug', args)
  },
  log(...args) {
    this.logIt('log', args)
  },
  table(...args) {
    this.logIt('table', args)
  },
  time(...args) {
    this.logIt('time', args)
  },
  timeEnd(...args) {
    this.logIt('timeEnd', args)
  },
  timeStamp(...args) {
    this.logIt('timeStamp', args)
  },
  trace(...args) {
    this.logIt('trace', args)
  },
  warn(...args) {
    this.logIt('warn', args)
  },
  error(err, context = {}) {
    if (this.shouldLog()) {
      logError(err, err.message, context)
    }
  },
  logIt(fn, args) {
    return (
      this.shouldLog() && window.console && console[fn] && console[fn](...args)
    )
  },
  shouldLog() {
    return !window.ORDORO_PREVENT_LOGGER
  },
}
