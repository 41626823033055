import PropTypes from 'prop-types'
import React from 'react'

import {HotKeyConnect} from '../HotKeys.js'

export default function ButtonLink({
  children,
  className,
  size,
  disabled,
  hotKey,
  ...props
}) {
  const button = (
    <button
      className={`btn btn--link ${size ? `btn--${size}` : ''} ${
        className || ''
      }`}
      type="button"
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )

  if (hotKey) {
    return (
      <HotKeyConnect code={hotKey} func={props.onClick} isDisabled={disabled}>
        {button}
      </HotKeyConnect>
    )
  }

  return button
}

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  hotKey: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
}
