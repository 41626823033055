export const CREATE_ORDER_PAGE = 'CREATE_ORDER_PAGE'
export const DASHBOARD_PAGE = 'DASHBOARD_PAGE'
export const SETTINGS_PAGE = 'SETTINGS_PAGE'
export const ORDER_LIST_PAGE = 'ORDER_LIST_PAGE'
export const ORDER_DETAIL_PAGE = 'ORDER_DETAIL_PAGE'
export const PRODUCT_LIST_PAGE = 'PRODUCT_LIST_PAGE'
export const PRODUCT_DETAIL_PAGE = 'PRODUCT_DETAIL_PAGE'
export const PURCHASE_ORDER_LIST_PAGE = 'PURCHASE_ORDER_LIST_PAGE'
export const PURCHASE_ORDER_DETAIL_PAGE = 'PURCHASE_ORDER_DETAIL_PAGE'
export const ANALYTICS_PAGE = 'ANALYTICS_PAGE'
export const DATA_IMPORT_PAGE = 'DATA_IMPORT_PAGE'
export const REPORTS_PAGE = 'REPORTS_PAGE'
export const PACK_SHIP_PAGE = 'PACK_SHIP_PAGE'
export const RETURN_ORDER_LIST_PAGE = 'RETURN_ORDER_LIST_PAGE'
export const RETURN_ORDER_DETAIL_PAGE = 'RETURN_ORDER_DETAIL_PAGE'
export const MOCK_ABODE_PAGE = 'MOCK_ABODE_PAGE'
export const MO_LIST_PAGE = 'MO_LIST_PAGE'
export const MO_DETAIL_PAGE = 'MO_DETAIL_PAGE'
export const BATCH_LIST_PAGE = 'BATCH_LIST_PAGE'
export const CALL_UP_PAGE = 'CALL_UP_PAGE'
export const WELCOME_PAGE = 'WELCOME_PAGE'

export default {
  CREATE_ORDER_PAGE,
  DASHBOARD_PAGE,
  SETTINGS_PAGE,
  ORDER_LIST_PAGE,
  ORDER_DETAIL_PAGE,
  PRODUCT_LIST_PAGE,
  PRODUCT_DETAIL_PAGE,
  PURCHASE_ORDER_LIST_PAGE,
  PURCHASE_ORDER_DETAIL_PAGE,
  ANALYTICS_PAGE,
  DATA_IMPORT_PAGE,
  REPORTS_PAGE,
  PACK_SHIP_PAGE,
  RETURN_ORDER_LIST_PAGE,
  RETURN_ORDER_DETAIL_PAGE,
  MOCK_ABODE_PAGE,
  MO_LIST_PAGE,
  MO_DETAIL_PAGE,
  BATCH_LIST_PAGE,
  WELCOME_PAGE,
}
