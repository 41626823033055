import {datadogRum} from '@datadog/browser-rum'

import {IS_DEV_ENV, IS_PROD_ENV} from '../constants/index.js'
import version from '../../../../version.json'

const DEBUG = false

const IGNORED_ERRORS = [
  /^APIversonError/,
  /^APIError/,
  /^XHRError/,
  /Error contacting the server/,
  /timeout of 0ms exceeded/,
  /Network Error/,
  /Timeout contacting the server/,
  /Request aborted/,
  /^PrintNode: Your VAT information is inconsistent or incomplete./,
  /^PrintNode: API Key not found/,
  /^PrintNode: You must validate your email address/,
  /^PrintNode: API rate limit exceeded/,
  /^PrintNode: Account not found/,
  /^PrintNode: This endpoint cannot be used by suspended Accounts/,
  /^PrintNode: You have one or more overdue invoices./,
  /ga is not defined/,
  /Can't find variable: ga/,
  /NS_ERROR_ABORT/,
]

const IGNORED_ERROR_TYPES = ['APIversonError', 'APIError', 'XHRError']

const IGNORED_STACKS = [/chrome-extension:\/\//]

if (DEBUG || IS_PROD_ENV || IS_DEV_ENV) {
  datadogRum.init({
    applicationId: 'ae6de44d-e62d-4bf4-98d5-ed98eb970242',
    clientToken: 'pub5426dec33063e3d0640d50f8ecb8daa3',
    site: 'datadoghq.com',
    service: 'pappy',
    env: IS_PROD_ENV ? 'prod' : 'dev',
    version: version.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: window.ORDORO_BOOTSTRAP.me ? 100 : 0,
    allowedTracingUrls: [/ordoro(-dev)?\.com/],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow', // use PRIVACY_MASK classname to hide shit
    enableExperimentalFeatures: ['feature_flags'],
    trackViewsManually: true,
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        (IGNORED_ERROR_TYPES.find(
          (ignoredErrorType) => event.error.type === ignoredErrorType,
        ) ||
          IGNORED_ERRORS.find((ignoredErrorMessage) =>
            event.error.message.match(ignoredErrorMessage),
          ) ||
          (event.error.stack &&
            IGNORED_STACKS.find((ignoredStack) =>
              event.error.stack.match(ignoredStack),
            )))
      ) {
        return false
      }
    },
  })
}

export default datadogRum
