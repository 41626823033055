import {logError} from '../../../common/error.js'
import parseHash from '../../../common/parseHash.js'

export const BASE = 'ordoro/ui'
export const NAVIGATE = `${BASE}/NAVIGATE`
export const SET_LOCATION = `${BASE}/SET_LOCATION`
export const SET_CURRENT_PAGE = `${BASE}/SET_CURRENT_PAGE`
export const SET_IS_STARTED = `${BASE}/SET_IS_STARTED`
export const SET_IS_LOADING = `${BASE}/SET_IS_LOADING`
export const SET_CONNECTIVITY_ERROR = `${BASE}/SET_CONNECTIVITY_ERROR`
export const SET_GLOBAL_ERROR = `${BASE}/SET_GLOBAL_ERROR`
export const SET_NOTIFICATION = `${BASE}/SET_NOTIFICATION`
export const OPEN_MODAL = `${BASE}/OPEN_MODAL`
export const CLOSE_MODAL = `${BASE}/CLOSE_MODAL`
export const SET_IS_REQUESTING = `${BASE}/SET_IS_REQUESTING`
export const APPLY_LOCATION_HREF = `${BASE}/APPLY_LOCATION_HREF`
export const SET_ENDICIA_ACCOUNT_ERROR = `${BASE}/SET_ENDICIA_ACCOUNT_ERROR`
export const SET_API_LOCKED_LATCH = `${BASE}/SET_API_LOCKED_LATCH`
export const SET_ZUCK_COMPANY_ID = `${BASE}/SET_ZUCK_COMPANY_ID`
export const SET_ZUCK_SUPPRESS_APP_MESSAGE = `${BASE}/SET_ZUCK_SUPPRESS_APP_MESSAGE`

export function navigate(url) {
  if (url[0] === '#') {
    return {
      type: NAVIGATE,
      payload: parseHash(url),
    }
  }

  return {
    type: APPLY_LOCATION_HREF,
    payload: url,
  }
}

export function setLocation(url) {
  return {
    type: SET_LOCATION,
    payload: parseHash(url),
  }
}

export function handlePollingError(payloadMessage, err) {
  if (err.message === 'Error contacting the server') {
    return setConnectivityError(payloadMessage, err)
  }

  return setGlobalError(payloadMessage, err)
}

export function setIsStarted(isStarted) {
  return {
    type: SET_IS_STARTED,
    payload: isStarted,
  }
}

export function setIsLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export function setConnectivityError(errorMessage) {
  return {
    type: SET_CONNECTIVITY_ERROR,
    payload: errorMessage,
  }
}

export function setGlobalError(errorMessage, error) {
  if (errorMessage) {
    logError(error, errorMessage)
  }

  // TODO: add more generic server messaging here
  // if (error.message === 'Error contacting the server') {
  // }

  // if ([502, 504].includes(get(error, 'response.status'))) {

  // }

  return {
    type: SET_GLOBAL_ERROR,
    payload: errorMessage,
  }
}

export function setNotification(payload) {
  return {
    type: SET_NOTIFICATION,
    payload,
  }
}

export function setEndiciaAccountError(payload) {
  return {
    type: SET_ENDICIA_ACCOUNT_ERROR,
    payload,
  }
}

export function setCurrentPage(payload) {
  return {
    type: SET_CURRENT_PAGE,
    payload,
  }
}

export function openModal(modal, meta = {}) {
  const action = {
    type: OPEN_MODAL,
    payload: {
      modalName: modal.modalName || modal,
      meta,
    },
  }

  return action
}

export function closeModal(meta = {}) {
  return {
    type: CLOSE_MODAL,
    payload: {meta},
  }
}

export function setIsRequesting(isRequesting) {
  const action = {
    type: SET_IS_REQUESTING,
    payload: isRequesting,
  }

  return action
}

export function determineIsRequesting(requestCount) {
  return (dispatch, getState) => {
    const {isRequesting} = getState().ui

    if (isRequesting && requestCount === 0) {
      dispatch(setIsRequesting(false))
    } else if (!isRequesting && requestCount > 0) {
      dispatch(setIsRequesting(true))
    }
  }
}

export function setAPILockedLatch(apiLockedLatch) {
  return {
    type: SET_API_LOCKED_LATCH,
    payload: apiLockedLatch,
  }
}

export function setZuckCompanyID(zuckCompanyID) {
  return {
    type: SET_ZUCK_COMPANY_ID,
    payload: zuckCompanyID,
  }
}

export function setZuckSuppressAppMessage(zuckSuppressAppMessage) {
  return {
    type: SET_ZUCK_SUPPRESS_APP_MESSAGE,
    payload: zuckSuppressAppMessage,
  }
}
