const BASE = 'ordoro/ui/forms'
export const SET_FORM = `${BASE}/SET_FORM`
export const UPDATE_FORM = `${BASE}/UPDATE_FORM`
export const SET_FORM_VALUE = `${BASE}/SET_FORM_VALUE`
export const REMOVE_FORM_VALUE = `${BASE}/REMOVE_FORM_VALUE`
export const UPDATE_FORM_OBJECT = `${BASE}/UPDATE_FORM_OBJECT`
export const ADD_FORM_ARRAY_ELEMENT = `${BASE}/ADD_FORM_ARRAY_ELEMENT`
export const REMOVE_FORM_ARRAY_ELEMENT = `${BASE}/REMOVE_FORM_ARRAY_ELEMENT`
export const UPDATE_FORMS = `${BASE}/UPDATE_FORMS`
export const REMOVE_FORM = `${BASE}/REMOVE_FORM`

export function setForm(key, form, meta = {}) {
  return {
    type: SET_FORM,
    payload: {key, form},
    meta,
  }
}

export function updateForm(key, form, meta = {}) {
  return {
    type: UPDATE_FORM,
    payload: {key, form},
    meta,
  }
}

export function setFormValue(key, path, value, meta = {}) {
  return {
    type: SET_FORM_VALUE,
    payload: {key, path, value},
    meta,
  }
}

export function removeFormValue(key, path, meta = {}) {
  return {
    type: REMOVE_FORM_VALUE,
    payload: {key, path},
    meta,
  }
}

export function updateFormObject(key, path, value, meta = {}) {
  return {
    type: UPDATE_FORM_OBJECT,
    payload: {key, path, value},
    meta,
  }
}

export function addFormArrayElement(key, path, element, meta = {}) {
  return {
    type: ADD_FORM_ARRAY_ELEMENT,
    payload: {key, path, element},
    meta,
  }
}

export function removeFormArrayElement(key, path, index, meta = {}) {
  return {
    type: REMOVE_FORM_ARRAY_ELEMENT,
    payload: {key, path, index},
    meta,
  }
}

export function updateForms(forms) {
  return {
    type: UPDATE_FORMS,
    payload: {forms},
  }
}

export function removeForm(key) {
  return {
    type: REMOVE_FORM,
    payload: {key},
  }
}
