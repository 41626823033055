export const HK_CONFIRM = 'ORDORO_001'
export const HK_CANCEL = 'ORDORO_002'
export const HK_SELECT_ALL = 'ORDORO_003'
export const HK_CREATE_LABEL = 'ORDORO_004'
export const HK_SEND_TO_PRINTER_A = 'ORDORO_005'
export const HK_VERIFY_CONTENTS = 'ORDORO_006'
export const HK_PACK_SHIP_START = 'ORDORO_007'
export const HK_PACK_SHIP_NEXT = 'ORDORO_008'
export const HK_PACK_SHIP_BACK = 'ORDORO_009'
export const HK_PRINT_TO_TAB_A = 'ORDORO_010'
export const HK_PRINT_TO_TAB_B = 'ORDORO_011'
export const HK_PRINT_TO_TAB_C = 'ORDORO_012'
export const HK_SEND_TO_PRINTER_B = 'ORDORO_013'
export const HK_SEND_TO_PRINTER_C = 'ORDORO_014'
export const HK_UP = 'ORDORO_015'
export const HK_DOWN = 'ORDORO_016'
export const HK_LEFT = 'ORDORO_017'
export const HK_RIGHT = 'ORDORO_018'
export const HK_READ_SCALE = 'ORDORO_019'
export const HK_APPLY_PRESET_REGEX = /^ORDORO_020_(?<args>.*)$/
export const HK_APPLY_PRESET_PREFIX = 'ORDORO_020_'

export const HOT_KEY_COMBOS = {
  [HK_CONFIRM]: ['enter', 'command+shift+enter', 'ctrl+shift+enter'],
  [HK_CANCEL]: 'esc',
  [HK_SELECT_ALL]: ['command+shift+a', 'ctrl+shift+a'],
  [HK_UP]: 'up',
  [HK_DOWN]: 'down',
  [HK_LEFT]: 'left',
  [HK_RIGHT]: 'right',
}

export const HOT_KEY_CODES = [
  HK_CONFIRM,
  HK_CANCEL,
  HK_SELECT_ALL,
  HK_CREATE_LABEL,
  HK_SEND_TO_PRINTER_A,
  HK_VERIFY_CONTENTS,
  HK_PACK_SHIP_START,
  HK_PACK_SHIP_NEXT,
  HK_PACK_SHIP_BACK,
  HK_PRINT_TO_TAB_A,
  HK_PRINT_TO_TAB_B,
  HK_PRINT_TO_TAB_C,
  HK_SEND_TO_PRINTER_B,
  HK_SEND_TO_PRINTER_C,
  HK_UP,
  HK_DOWN,
  HK_LEFT,
  HK_RIGHT,
  HK_READ_SCALE,
  HK_APPLY_PRESET_REGEX,
]
