export function stringify(params) {
  if (!params) {
    return ''
  }

  const keyValues = []

  function addKeyValue(key, val) {
    keyValues.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
  }

  for (let key in params) {
    const val = params[key]

    if (Array.isArray(val)) {
      val.forEach((v) => addKeyValue(key, v))
    } else if (val !== null && val !== undefined) {
      addKeyValue(key, val)
    }
  }

  return keyValues.join('&')
}

export function parse(url) {
  if (!url || typeof url !== 'string') {
    return {}
  }

  return url
    .replace(/^\?/, '')
    .split('&')
    .reduce((prev, param) => {
      const parts = param.replace(/\+/g, ' ').split('=')
      const key = decodeURIComponent(parts[0])
      const val = parts[1] === undefined ? null : decodeURIComponent(parts[1])

      if (!prev[key]) {
        prev[key] = val
      } else if (Array.isArray(prev[key])) {
        prev[key].push(val)
      } else {
        prev[key] = [prev[key], val]
      }

      return prev
    }, {})
}

export function stringifyURL(url, params) {
  const stringifiedParams = stringify(params)

  if (!stringifiedParams.length) {
    return url
  }

  return `${url}?${stringifiedParams}`
}

export function buildPath(pathComponents, query, {trailingSlash = false} = {}) {
  return (
    '/' +
    stringifyURL(
      pathComponents
        .filter((part) => part)
        .map((part) => encodeURIComponent(part))
        .join('/') + (trailingSlash ? '/' : ''),
      query,
    )
  )
}

// The API schema validation likes strings so this function makes an object that
// looks like it just passed through a query param parser (because it did)
export function stringIt(obj) {
  return parse(stringify(obj))
}
