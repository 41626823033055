export function getNounsFromValue(value) {
  return value
    .toLowerCase()
    .replace(/\W/g, ' ')
    .replace(/[_-]/g, ' ')
    .split(/\W+/)
    .filter((word) => word)
    .reduce((prev, word) => {
      if (!prev.includes(word)) {
        prev.push(word)
      }
      return prev
    }, [])
}

function allThem(noun, result) {
  result.add(noun.toLowerCase())

  if (noun.length > 1) {
    return allThem(noun.slice(0, -1), result)
  }

  return result
}

export function tokenizeOptions(options) {
  return options.reduce((prev, option) => {
    const ngrams = new Set()

    for (const noun of option.nouns) {
      allThem(noun, ngrams)
    }

    for (const ngram of ngrams) {
      const token = prev.get(ngram) || {
        options: new Set(),
      }

      token.options.add(option)

      prev.set(ngram, token)
    }

    return prev
  }, new Map())
}

export function filterOptions(searchTerm, optionTokens) {
  let intersection = new Set()
  const textList = getNounsFromValue(searchTerm)

  // Nothing to search to make a list of hints
  if (textList.length === 0) {
    return []
  }

  // Search through the list and return a list of options that matches all items in the list
  for (let i = 0; i < textList.length; i++) {
    const text = textList[i]

    if (!text) {
      continue
    }

    const optionToken = optionTokens.get(text)

    if (!optionToken) {
      return []
    }

    if (i === 0) {
      intersection = new Set([...optionToken.options])
    } else {
      intersection = new Set(
        [...intersection].filter((x) => optionToken.options.has(x)),
      )
    }
  }

  return [...intersection]
}
