;(function () {
  if (
    typeof window.Element === 'undefined' ||
    'classList' in document.documentElement
  )
    return

  // `Array.indexOf` polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf
  if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (searchElement /*, fromIndex */) {
      'use strict'
      if (this == null) {
        throw new TypeError()
      }
      var t = Object(this)
      var len = t.length >>> 0
      if (len === 0) {
        return -1
      }
      var n = 0
      if (arguments.length > 1) {
        n = Number(arguments[1])
        if (n != n) {
          // shortcut for verifying if it's NaN
          n = 0
        } else if (n != 0 && n != Infinity && n != -Infinity) {
          n = (n > 0 || -1) * Math.floor(Math.abs(n))
        }
      }
      if (n >= len) {
        return -1
      }
      var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0)
      for (; k < len; k++) {
        if (k in t && t[k] === searchElement) {
          return k
        }
      }
      return -1
    }
  }

  var prototype = Array.prototype,
    indexOf = prototype.indexOf,
    slice = prototype.slice,
    push = prototype.push,
    splice = prototype.splice,
    join = prototype.join

  function DOMTokenList(el) {
    this._element = el
    if (el.className != this._classCache) {
      this._classCache = el.className

      if (!this._classCache) return

      // The className needs to be trimmed and split on whitespace
      // to retrieve a list of classes.
      var classes = this._classCache.replace(/^\s+|\s+$/g, '').split(/\s+/),
        i
      for (i = 0; i < classes.length; i++) {
        push.call(this, classes[i])
      }
    }
  }

  function setToClassName(el, classes) {
    el.className = classes.join(' ')
  }

  DOMTokenList.prototype = {
    add: function (token) {
      if (this.contains(token)) return
      push.call(this, token)
      setToClassName(this._element, slice.call(this, 0))
    },
    contains: function (token) {
      return indexOf.call(this, token) !== -1
    },
    item: function (index) {
      return this[index] || null
    },
    remove: function (token) {
      var i = indexOf.call(this, token)
      if (i === -1) {
        return
      }
      splice.call(this, i, 1)
      setToClassName(this._element, slice.call(this, 0))
    },
    toString: function () {
      return join.call(this, ' ')
    },
    toggle: function (token) {
      if (indexOf.call(this, token) === -1) {
        this.add(token)
      } else {
        this.remove(token)
      }
    },
  }

  window.DOMTokenList = DOMTokenList

  function defineElementGetter(obj, prop, getter) {
    if (Object.defineProperty) {
      Object.defineProperty(obj, prop, {
        get: getter,
      })
    } else {
      obj.__defineGetter__(prop, getter)
    }
  }

  defineElementGetter(Element.prototype, 'classList', function () {
    // eslint-disable-next-line @babel/no-invalid-this
    return new DOMTokenList(this)
  })
})()
