import XHR from './XHR.js'
import logger from './logger.js'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
const VERDE_URL = '/verde'

function formatError(param, message, status) {
  if (param && message) {
    return `${param}: ${message}`
  }
  if (message) {
    return message
  }
  return `${status}`
}

export function VerdeError(response) {
  if (!response.json) {
    response.json = {error_message: response.raw, param: null}
  }

  this.response = response
  this.error_message = response.json.error_message || response.json.message
  this.param = response.json.param

  this.name = 'VerdeError'
  this.message = formatError(
    this.param,
    this.error_message,
    this.response.status,
  )
  this.stack = new Error(this.error_message).stack
}
VerdeError.prototype = Object.create(Error.prototype)

class VerdeXHR extends XHR {}
VerdeXHR.prototype.Error = VerdeError

function send(method, url, data, headers = DEFAULT_HEADERS) {
  const fullURL = `${VERDE_URL}${url}`
  const xhr = new VerdeXHR(method, fullURL, data, headers)

  return xhr.send().catch((err) => {
    logger.warn(err)

    throw err
  })
}

export default {
  send,
  get: send.bind(send, 'get'),
  post: send.bind(send, 'post'),
}
