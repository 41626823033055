import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import {HotKeyConnect} from '../HotKeys.js'

export default function ButtonSecondary({
  children,
  className,
  hotKey,
  size,
  ...props
}) {
  const button = (
    <button
      className={classNames(
        'btn btn--secondary inline-block',
        `btn--${size}`,
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )

  if (hotKey) {
    return (
      <HotKeyConnect code={hotKey} func={props.onClick}>
        {button}
      </HotKeyConnect>
    )
  }

  return button
}

ButtonSecondary.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  hotKey: PropTypes.string,
  size: PropTypes.string,
}

ButtonSecondary.defaultProps = {
  size: 'md',
  type: 'button',
}
