import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  formSelector,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import deferPromise from '../../deferPromise.js'
import verde from '../../common/verde.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {
  getPaymentAccounts,
  getPaymentAccount,
  paymentAccountSelector,
  paymentAccountsSortedByNameSelector,
  updatePaymentAccount,
  hasPaymentAccountIntegrationIssueSelector,
} from '../../data/paymentAccounts.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import api from '../../common/api.js'
import {showConfirmMessageModal} from '../../common/components/Modal/ConfirmMessageModal.js'
import loadPlaid from '../../libs/plaid.js'
import TextInput from '../../common/components/TextInput.js'
import {isPresent} from '../../common/utils.js'

const MODAL_FORM = 'PLAID_MODAL'
const CACHED_PLAID_LINK_TOKEN = 'plaid.link_token'

export function showPlaidModal() {
  const deferredPromise = deferPromise()

  const link_token_json = localStorage.getItem(CACHED_PLAID_LINK_TOKEN)

  let link_token
  if (link_token_json) {
    try {
      const json = JSON.parse(link_token_json)

      if (new Date(json.expiration) > new Date()) {
        link_token = json.link_token
      } else {
        localStorage.removeItem(CACHED_PLAID_LINK_TOKEN)
      }
    } catch (err) {
      // not good but do not care
    }
  }

  setForm(MODAL_FORM, {
    link_token,
    autoStart: !!link_token,
    isSaving: false,
    isLoading: false,
    plaidResultError: null,
    serverError: null,
    deferredPromise,
  })

  return deferredPromise.promise
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal(hasCreditCard = false) {
  localStorage.removeItem(CACHED_PLAID_LINK_TOKEN)

  const form = modalFormSelector(getState())

  removeForm(MODAL_FORM)

  form.deferredPromise.resolve(hasCreditCard)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function deletePaymentAccount(paymentAccountID) {
  const willDelete = await showConfirmMessageModal({
    title: 'Delete Payment Account',
    message: 'Do you want to delete this payment account?',
  })

  if (!willDelete) {
    return
  }

  try {
    updateModalForm({serverError: null})

    await api.delete(`/company/payment/${paymentAccountID}`)

    showMessageToast('Deleted Payment Account')

    await getPaymentAccounts()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function makePaymentAccountDefault(paymentAccountID) {
  const willChange = await showConfirmMessageModal({
    title: 'Make Default',
    message:
      'Do you want to make this payment account your default payment method?',
  })

  if (!willChange) {
    return
  }

  try {
    updateModalForm({serverError: null})

    for (const paymentAccount of paymentAccountsSortedByNameSelector(
      getState(),
    )) {
      if (!paymentAccount.is_default) {
        continue
      }

      await api.put(`/company/payment/${paymentAccount.id}`, {
        is_default: false,
      })
    }

    await api.put(`/company/payment/${paymentAccountID}`, {is_default: true})

    showMessageToast('Updated Payment Account')

    await getPaymentAccounts()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function getLinkToken(params) {
  try {
    const {json} = await verde.post('/plaid/create_link_token', params)

    updateModalForm({
      link_token: json.link_token,
      link_token_expiration: json.expiration,
    })

    localStorage.setItem(CACHED_PLAID_LINK_TOKEN, JSON.stringify(json))
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function startLink() {
  const Plaid = await loadPlaid()

  const {link_token} = modalFormSelector(getState())

  if (!link_token) {
    return
  }

  const handler = Plaid.create({
    token: link_token,
    onSuccess,
    onEvent,
    onExit,
    onLoad: () => {
      updateModalForm({isLoading: false})
    },
  })

  handler.open()
}

export async function onSuccess(public_token) {
  try {
    const {
      json: {payment_account: touchedPaymentAccounts},
    } = await verde.post('/plaid/public_token_exchange', {
      public_token,
    })

    showMessageToast('Added Bank Account')

    await getPaymentAccounts()

    // ensure we have a legal name for each account.
    // some workflows will give us the legal name but some
    // do not have permission
    for (const paymentAccount of touchedPaymentAccounts) {
      if (paymentAccount.activation_response.legal_name) {
        continue
      }

      await editLegalName(paymentAccount.id)
    }
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function onEvent(_eventName, _metadata) {
  // log onEvent callbacks from Link
  // https://plaid.com/docs/link/web/#onevent
}

export async function onExit(_error, _metadata) {
  // log onExit callbacks from Link, handle errors
  // https://plaid.com/docs/link/web/#onexit

  showMessageToast('Account Setup Cancelled')
}

async function createAccount() {
  updateModalForm({isLoading: true})

  await getLinkToken()

  await startLink()
}

async function resumeLink(paymentAccountID) {
  const paymentAccount = paymentAccountSelector(getState(), {paymentAccountID})
  const hasPaymentAccountIntegrationIssue = hasPaymentAccountIntegrationIssueSelector(
    getState(),
    {paymentAccountID},
  )

  const params = {}

  const access_token = get(paymentAccount, [
    'activation_response',
    'access_token',
  ])
  const authorization_id = get(paymentAccount, [
    'payment_response',
    'authorization',
    'id',
  ])

  if (hasPaymentAccountIntegrationIssue && authorization_id) {
    params.authorization_id = authorization_id
  } else if (access_token) {
    params.access_token = access_token
  }

  updateModalForm({isLoading: true})

  await getLinkToken(params)

  await startLink()
}

async function editLegalName(paymentAccountID) {
  try {
    let paymentAccount = paymentAccountSelector(getState(), {paymentAccountID})
    let {
      activation_response: {account = {}, legal_name},
    } = paymentAccount

    legal_name = await showConfirmMessageModal({
      title: 'Edit Legal Name',
      Message({form, updateModalForm}) {
        return (
          <>
            <div>
              <div className="flex--justify-nowrap">
                <div>{account.name}</div>
                <div>{account.mask}</div>
              </div>
              <div>{account.official_name}</div>
            </div>
            <TextInput
              label="Full Legal Name"
              id="legal_name"
              required
              value={form.confirmValue}
              onChange={(value) => updateModalForm({confirmValue: value})}
            />
          </>
        )
      },
      confirmText: 'Save',
      cancelText: 'Cancel',
      errorSelector(state, {formName}) {
        const errors = {}
        const {confirmValue} = formSelector(state, {formName})

        if (!isPresent(confirmValue)) {
          errors.confirmValue = 'Full Legal Name is required'
          errors.preventSave = true
        }
      },
      confirmValue: legal_name || '',
    })

    if (!legal_name) {
      return
    }

    paymentAccount = await getPaymentAccount(paymentAccountID)

    const params = {
      activation_response: {...paymentAccount.activation_response, legal_name},
    }

    await updatePaymentAccount(paymentAccountID, params)
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

function PaymentAccountRow({
  paymentAccount: {id, activation_response, is_default},
}) {
  const {account = {}, legal_name} = activation_response
  const hasPaymentAccountIntegrationIssue = useSelector((state) =>
    hasPaymentAccountIntegrationIssueSelector(state, {paymentAccountID: id}),
  )
  const canResumeLink =
    hasPaymentAccountIntegrationIssue ||
    account.verification_status === 'pending_manual_verification'

  return (
    <tr>
      <td className="table__td">
        <div className="flex--justify-nowrap">
          <div>{account.name}</div>
          <div>{account.mask}</div>
        </div>
        <div>{account.official_name}</div>
      </td>
      <td className="table__td">
        <ButtonLink onClick={() => editLegalName(id)}>
          {legal_name || 'edit'}
        </ButtonLink>
      </td>
      <td className="table__td table__td--md align-right">
        {canResumeLink ? (
          <ButtonPrimary size="xx-sm" isOutlined onClick={() => resumeLink(id)}>
            {hasPaymentAccountIntegrationIssue ? 'Reauthorize' : 'Finish Setup'}
          </ButtonPrimary>
        ) : is_default ? (
          <strong className="label__callout label__callout--blue fs-n0">
            DEFAULT
          </strong>
        ) : (
          <ButtonPrimary
            className="btn--make-default-td"
            size="xx-sm"
            isOutlined
            onClick={() => makePaymentAccountDefault(id)}
          >
            Make Default
          </ButtonPrimary>
        )}
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="no-underline"
          title="Remove Account"
          onClick={() => deletePaymentAccount(id)}
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

PaymentAccountRow.propTypes = {
  paymentAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    activation_response: PropTypes.object.isRequired,
    is_default: PropTypes.bool.isRequired,
  }),
}

function PlaidModal({form}) {
  const paymentAccounts = useSelector(paymentAccountsSortedByNameSelector)

  return (
    <ConfirmModal
      title="Manage Payment Accounts"
      modalSize="sm"
      onCancel={() => closeModal()}
      cancelText="Close"
      isSaving={form.isSaving}
      error={form.serverError || form.plaidResultError}
    >
      <div className="alert alert--standard margin-bottom-20">
        <p className="fs-n0 margin-bottom-0">
          This account will be used for postage purchases.
        </p>
      </div>
      <table className="table fs-00">
        <thead>
          <tr>
            <th className="table__th table__th--md align-left">Account Name</th>
            <th className="table__th table__th--md align-left">Legal Name</th>
            <th className="table__th table__th--md">&nbsp;</th>
            <th className="table__th table__th--md">&nbsp;</th>
          </tr>
        </thead>
        <tbody className="table__tbody table__tbody--lines">
          {paymentAccounts.map((paymentAccount) => (
            <PaymentAccountRow
              key={paymentAccount.id}
              paymentAccount={paymentAccount}
            />
          ))}
        </tbody>
      </table>
      <ButtonPrimary
        isOutlined
        size="sm"
        onClick={() => createAccount()}
        isLoading={form.isLoading}
      >
        Add
      </ButtonPrimary>
    </ConfirmModal>
  )
}

PlaidModal.propTypes = {
  form: PropTypes.shape({
    link_token: PropTypes.string,
    autoStart: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    plaidResultError: PropTypes.string,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(PlaidModal, (state) => {
  let form = modalFormSelector(state)

  if (!form && localStorage.getItem(CACHED_PLAID_LINK_TOKEN)) {
    showPlaidModal()

    form = modalFormSelector(state)
  }

  return form
})
