import React from 'react'
import classNames from 'classnames'
import ReactDOM from 'react-dom'

import {
  setForm,
  removeForm,
  formsSelector,
  useSelector,
} from '../../../store.js'
import MessageToast from './MessageToast.js'

export const TOAST = 'TOAST'

const ToastComponents = {
  MessageToast,
}

export function showMessageToast(message, options = {}) {
  setForm(TOAST, {
    options: {
      ...options,
      message,
    },
    component: 'MessageToast',
  })
}

export function closeToast() {
  removeForm(TOAST)
}

export function toastSelector(state) {
  return formsSelector(state)[TOAST] || toastSelector.default
}
toastSelector.default = {
  options: {},
  component: null,
}

export function toastOptionsSelector(state) {
  return toastSelector(state).options
}

export function toastComponentSelector(state) {
  return toastSelector(state).component
}

export default function Toast() {
  const component = useSelector(toastComponentSelector)
  const options = useSelector(toastOptionsSelector)
  const ToastComponent = ToastComponents[component]

  return ReactDOM.createPortal(
    <div className={classNames('alert alert--global', {in: !!component})}>
      {ToastComponent && (
        <ToastComponent options={options} closeToast={closeToast} />
      )}
    </div>,
    document.querySelector('#toast-root'),
  )
}
