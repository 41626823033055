import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

export default function ErrorMessage({children, className}) {
  if (!children) {
    return null
  }
  return (
    <pre className={classNames('wrap--error-wb lh-sm', className)}>
      <small className="error">{children}</small>
    </pre>
  )
}

ErrorMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
}

ErrorMessage.defaultProps = {
  children: null,
  className: null,
}
