import {createSelector} from 'reselect'

import {setForm, formsSelector, updateForm, getState} from '../store.js'
import apiverson from '../common/apiverson.js'
import {isNumeric} from '../common/utils.js'
import userflow from '../common/analytics/userflow.js'
import api from '../common/api.js'
import {
  defaultPaymentAccountSelector,
  getPaymentAccounts,
  setPaymentAccount,
} from './paymentAccounts.js'

export const ACCOUNT = 'ACCOUNT'

export function accountSelector(state) {
  return formsSelector(state)[ACCOUNT] || accountSelector.default
}
accountSelector.default = {}

export function accountHaveLoadedSelector(state) {
  return !!formsSelector(state)[ACCOUNT]
}

export const accountBalanceSelector = createSelector(
  accountSelector,
  ({balance}) => (isNumeric(balance) ? balance : null), // `null` means: we haven't got it yet
)

export const dailyPurchaseLimitSelector = createSelector(
  accountSelector,
  (account) => account.daily_purchase_limit || 0,
)

export const todaysPurchasesSelector = createSelector(
  accountSelector,
  (account) => account.todays_purchases || 0,
)

export function amountPostageThatCanBePurchasedSelector(state, {amount}) {
  const dailyPurchaseLimit = dailyPurchaseLimitSelector(state)
  const todaysPurchases = todaysPurchasesSelector(state)

  const amountUntilLimit = dailyPurchaseLimit - todaysPurchases

  if (amount <= 0 || amountUntilLimit <= 0) {
    return 0
  }

  return amount > amountUntilLimit ? amountUntilLimit : amount
}

export function amountExceedsLimitSelector(state, {amount}) {
  const dailyPurchaseLimit = dailyPurchaseLimitSelector(state)
  const todaysPurchases = todaysPurchasesSelector(state)

  return amount > 0 && amount + todaysPurchases > dailyPurchaseLimit
}

export function setAccount(account) {
  setForm(ACCOUNT, account)
}

export function setAccountBalance(balance) {
  updateForm(ACCOUNT, {balance})
}

export async function getAccount() {
  const {json} = await apiverson.get('/account/balance')

  setAccount(json)

  userflow.updateGroup(
    Object.entries(json).reduce((prev, [key, value]) => {
      prev[`account__${key}`] = value

      return prev
    }, {}),
  )
}

export async function addToAccountBalance(amount, description) {
  const defaultPaymentAccount = defaultPaymentAccountSelector(getState())

  if (defaultPaymentAccount) {
    await initiateTransfer(defaultPaymentAccount, amount)

    return
  }

  const params = {
    amount,
    description,
  }

  const {json} = await apiverson.post('/account/add_funds', params)

  setAccountBalance(json.balance)

  await getAccount()
}

export async function initiateTransfer(paymentAccount, amount) {
  const {json: authorizeResponse} = await api.post(
    `/company/payment/${paymentAccount.id}/authorize/`,
    {
      amount: Number(amount),
    },
  )

  paymentAccount = authorizeResponse
  setPaymentAccount(paymentAccount)

  console.log({authorizeResponse})

  const authorization = paymentAccount.payment_response.authorization

  if (authorization) {
    const {json: transfer} = await api.post(
      `/company/payment/${paymentAccount.id}/transfer/`,
      {
        authorization_id: authorization.id,
      },
    )

    console.log({transfer})
  } else {
    console.log('no transfer')
  }

  await getPaymentAccounts()
}
