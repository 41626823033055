export const STARTER_PLAN = 'free'
export const BASIC_PLAN = 'basic'
export const EXPRESS_PLAN = 'express'
export const PRO_PLAN = 'pro'
export const ENTERPRISE_PLAN = 'enterprise'
export const ESSENTIALS_PLAN = 'essentials'
export const ADVANCED_PLAN = 'advanced'
export const PREMIUM_PLAN = 'premium'
export const APPS_PLAN = 'apps'

export const PLAN_NAME_LOOKUP = {
  [STARTER_PLAN]: 'Starter',
  [BASIC_PLAN]: 'Basic',
  [EXPRESS_PLAN]: 'Express',
  [PRO_PLAN]: 'Pro',
  [ENTERPRISE_PLAN]: 'Enterprise',
  [ESSENTIALS_PLAN]: 'Essentials',
  [ADVANCED_PLAN]: 'Advanced',
  [PREMIUM_PLAN]: 'Premium',
  [APPS_PLAN]: 'Apps',
}

export const NEW_PLANS = [ESSENTIALS_PLAN, ADVANCED_PLAN, PREMIUM_PLAN]
