import PropTypes from 'prop-types'
import React, {useEffect, useRef, useCallback} from 'react'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'

import {dispatch, useSelector} from '../../../../store.js'
import {isParentOf} from '../../../dom.js'
import {HK_CANCEL} from '../../../constants/HotKeys.js'
import {HotKeyConnect} from '../../HotKeys.js'
import {closeModal} from '../../../../redux/actions/ui/index.js'
import {isSuperuserSelector} from '../../../../data/me.js'
import {locationSelector} from '../../../../redux/selectors/ui/location.js'

function enableNoScroll() {
  const html = document.querySelector('html')

  const scrollTop = html.scrollTop

  html.classList.add('no-scroll')

  html.style.setProperty('top', `${-scrollTop}px`)
}

function disableNoScroll() {
  const html = document.querySelector('html')

  const scrollTop = parseInt(html.style.top) || 0

  html.classList.remove('no-scroll')

  html.style.removeProperty('top')

  html.scrollTop = -scrollTop
}

export default function BaseModal({
  modalName,
  className,
  title,
  preventClose,
  onClose,
  children,
}) {
  const isSuperuser = useSelector(isSuperuserSelector)
  const location = useSelector(locationSelector)

  // Only call close once so setup a latch
  const hasClosedRef = useRef(false)
  const close = useCallback(() => {
    if (hasClosedRef.current) {
      return
    }

    if (!isSuperuser && preventClose) {
      return
    }

    hasClosedRef.current = true

    if (onClose) {
      // this function call might cause our `close()` function to be called again
      onClose()
    }

    dispatch(closeModal())
  }, [isSuperuser, preventClose, onClose])

  useEffect(() => {
    enableNoScroll()

    return disableNoScroll
  }, [])

  useEffect(() => {
    // blur active element if active element is not in the modal section
    if (
      !isParentOf(document.activeElement, document.querySelector('#modal-root'))
    ) {
      document.activeElement.blur()
    }
  }, [])

  // force close modal on page navigation.
  const locationRef = useRef(location)
  useEffect(() => {
    // modal loaded on page load and location wasn't set initially
    // so keep updating it with the most current location
    if (locationRef.current.pathComponents.length === 0) {
      locationRef.current = location
    }

    if (locationRef.current !== location) {
      close()
    }
  }, [location])

  return (
    <div className="wrap--modal">
      <HotKeyConnect code={HK_CANCEL} func={close} />
      <div
        className={classNames(
          'modal',
          `meta-modal-name-${kebabCase(modalName)}`,
          className,
        )}
      >
        <div className="modal__header">
          <div className="wrap--row">
            <div className="medium-12 columns padding-right-0 flex--justify">
              <h3 className="margin-bottom-0 margin-top-0">{title}</h3>
              {(isSuperuser || !preventClose) && (
                <button
                  className="btn btn--link no-underline btn--close"
                  onClick={close}
                >
                  <span className="i--close fs-01" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

BaseModal.propTypes = {
  title: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  preventClose: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
}

BaseModal.modalName = 'BASE_MODAL'

export {default as Content} from './Content.js'
export {default as Footer} from './Footer.js'
export {default as ButtonPrimary} from './ButtonPrimary.js'
export {default as ButtonSecondary} from './ButtonSecondary.js'
export {default as ButtonCancel} from './ButtonCancel.js'
