let isMocked = false
let mockInjectedObjects
const mockEvents = []

function mockLoadScript(src) {
  mockEvents.push(src)
  const injectedObject = mockInjectedObjects[src] || {}

  Object.keys(injectedObject).forEach((key) => {
    window[key] = injectedObject[key]
  })

  return Promise.resolve()
}

export default function loadScript(src) {
  if (isMocked) {
    return mockLoadScript(src)
  }

  return new Promise((resolve, reject) => {
    const firstScriptTag = document.getElementsByTagName('script')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.onload = resolve
    script.onerror = reject
    script.src = src

    firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
  })
}

export function setupMockForLoadScript(injectedObjects) {
  isMocked = true
  mockEvents.length = 0
  mockInjectedObjects = injectedObjects

  return mockEvents
}
