import {createSelector} from 'reselect'

import {
  CREATE_ORDER_PAGE,
  ORDER_LIST_PAGE,
  ORDER_DETAIL_PAGE,
  PACK_SHIP_PAGE,
  PRODUCT_LIST_PAGE,
} from '../../../common/constants/Pages.js'
import {
  appMessageSelector,
  canNotReactivateSelector,
} from '../../../data/company.js'

export const uiSelector = createSelector(
  (state) => state,
  ({ui}) => ui,
)

export const apiLockedLatchSelector = createSelector(
  uiSelector,
  ({apiLockedLatch}) => apiLockedLatch,
)

export const isStartedSelector = createSelector(
  uiSelector,
  ({isStarted}) => isStarted,
)

export const isLoadingSelector = createSelector(
  uiSelector,
  ({isLoading}) => isLoading,
)

export const currentDropdownSelector = createSelector(
  uiSelector,
  ({currentDropdown}) => currentDropdown,
)

export const endiciaAccountErrorSelector = createSelector(
  uiSelector,
  ({endiciaAccountError}) => endiciaAccountError,
)

export const currentPageSelector = createSelector(
  uiSelector,
  ({currentPage}) => currentPage,
)

export const modalSelector = createSelector(uiSelector, ({modal}) => modal)

export const currentModalSelector = createSelector(
  modalSelector,
  ({current}) => current,
)

export const modalQueueSelector = createSelector(
  modalSelector,
  ({queue}) => queue,
)

export const zuckCompanyIDSelector = createSelector(
  uiSelector,
  ({zuckCompanyID}) => zuckCompanyID,
)

export const globalErrorSelector = createSelector(
  uiSelector,
  ({globalError}) => globalError,
)

export const globalErrorSummarySelector = createSelector(
  globalErrorSelector,
  ({summary}) => summary,
)

export const globalErrorDetailsSelector = createSelector(
  globalErrorSelector,
  ({details}) => details,
)

export const hasBlockingAppMessageSelector = createSelector(
  appMessageSelector,
  isStartedSelector,
  (appMessage, isStarted) =>
    appMessage && (!appMessage.canDismiss || !isStarted),
)

export const showMainSelector = createSelector(
  hasBlockingAppMessageSelector,
  (state) => canNotReactivateSelector(state),
  (hasBlockingAppMessage, canNotReactivate) =>
    !hasBlockingAppMessage && !canNotReactivate,
)

export const needsIversionClassSelector = createSelector(
  currentPageSelector,
  (currentPage) =>
    [
      ORDER_LIST_PAGE,
      ORDER_DETAIL_PAGE,
      CREATE_ORDER_PAGE,
      PACK_SHIP_PAGE,
      PRODUCT_LIST_PAGE,
    ].includes(currentPage),
)
