import {useEffect} from 'react'

export default function useAutoFocus(ref, autoFocus) {
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.focus()
          ref.current.select()
        }
      }, 100)
    }
  }, [autoFocus])
}
