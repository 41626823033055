import Combokeys from 'combokeys'

const combokeys = new Combokeys(document.documentElement)

// The default behavior prevents "enter" from triggering inside of simple text input
// We want to be able to "confirm" modals when the input is focused so we need combokeys to not stop
// when "enter" is encountered in a input field
// We still want selects and textareas to stop because they use the "enter" key themselves.
combokeys.stopCallback = (e, element, combo) => {
  const tagName = element.tagName

  if (['SELECT', 'TEXTAREA'].includes(tagName)) {
    return true
  }

  if (tagName === 'INPUT' && !['esc', 'enter', 'up', 'down'].includes(combo)) {
    return true
  }

  if (tagName === 'BUTTON' && combo === 'enter') {
    return true
  }

  return false
}

export default combokeys
