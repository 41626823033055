import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import {
  setForm,
  updateForm,
  formsSelector,
  removeFormValue,
  getState,
} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import api from '../common/api.js'
import {showPlaidModal} from '../ordoro/Modals/PlaidModal.js'

export const PAYMENT_ACCOUNTS = 'PAYMENT_ACCOUNTS'

export function paymentAccountsSelector(state) {
  return (
    formsSelector(state)[PAYMENT_ACCOUNTS] || paymentAccountsSelector.default
  )
}
paymentAccountsSelector.default = {}

export function paymentAccountsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PAYMENT_ACCOUNTS]
}

export function paymentAccountSelector(state, {paymentAccountID}) {
  return paymentAccountsSelector(state)[paymentAccountID]
}

export const paymentAccountsSortedByNameSelector = createSelector(
  paymentAccountsSelector,
  (paymentAccounts) =>
    sortBy(paymentAccounts, 'name').filter(({archive_date}) => !archive_date),
)

export function defaultPaymentAccountSelector(state) {
  return paymentAccountsSortedByNameSelector(state).find(
    ({is_default}) => is_default,
  )
}

export const PLAID_TRANSFER_STATUS_PENDING = 'pending'

export function paymentAccountTransferStatusSelector(
  state,
  {paymentAccountID},
) {
  const paymentAccount = paymentAccountSelector(state, {paymentAccountID})

  return get(paymentAccount, ['payment_response', 'transfer', 'status'], null)
}

export function hasInprogressAccountTransferSelector(state) {
  const defaultPaymentAccount = defaultPaymentAccountSelector(state)

  if (!defaultPaymentAccount) {
    return false
  }

  const transferStatus = paymentAccountTransferStatusSelector(state, {
    paymentAccountID: defaultPaymentAccount.id,
  })

  return [PLAID_TRANSFER_STATUS_PENDING].includes(transferStatus)
}

export const PLAID_USER_ACTION_REQUIRED = 'user_action_required'

export function hasPaymentAccountIntegrationIssueSelector(
  state,
  {paymentAccountID},
) {
  const paymentAccount = paymentAccountSelector(state, {paymentAccountID})

  return (
    get(paymentAccount, ['payment_response', 'authorization', 'decision']) ===
    PLAID_USER_ACTION_REQUIRED
  )
}

export function paymentAccountIntegrationIssuesSelector(state) {
  const paymentAccounts = paymentAccountsSortedByNameSelector(state)

  return paymentAccounts
    .filter((paymentAccount) =>
      hasPaymentAccountIntegrationIssueSelector(state, {
        paymentAccountID: paymentAccount.id,
      }),
    )
    .map((paymentAccount) => ({
      id: paymentAccount.id,
      type: 'payment_account',
      name: getPaymentAccountName(paymentAccount),
      message: 'needs to be reauthorized',
      errorType: 'needs_reauth',
      onClick() {
        showPlaidModal()
      },
    }))
}

export function anyPaymentAccountHasIntegrationIssueSelector(state) {
  return paymentAccountIntegrationIssuesSelector(state).length > 0
}

export function defaultPaymentAccountHasIntegrationIssueSelector(state) {
  const paymentAccount = defaultPaymentAccountSelector(state)

  return (
    !!paymentAccount &&
    hasPaymentAccountIntegrationIssueSelector(state, {
      paymentAccountID: paymentAccount.id,
    })
  )
}

export function getPaymentAccountName(paymentAccount) {
  return get(paymentAccount, ['activation_response', 'account', 'name'])
}

export function setPaymentAccounts(paymentAccounts) {
  setForm(PAYMENT_ACCOUNTS, keyBy(paymentAccounts, 'id'))
}

export function setPaymentAccount(paymentAccount) {
  updateForm(PAYMENT_ACCOUNTS, {[paymentAccount.id]: paymentAccount})
}

export function removePaymentAccount(paymentAccountID) {
  removeFormValue(PAYMENT_ACCOUNTS, [paymentAccountID])
}

export async function getPaymentAccounts() {
  try {
    const paymentAccounts = await fetchAllAPI(
      '/company/payment/',
      'payment_account',
    )

    setPaymentAccounts(paymentAccounts)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting payment accounts.',
        details: err.message || err.error_message,
      },
      `Error getting payment accounts. ${err.error_message || err.message}`,
      err,
    )

    setPaymentAccounts([])
  }

  return paymentAccountsSelector(getState())
}

export const getPaymentsAccountsDebounced = debounce(getPaymentAccounts, 500)

export async function getPaymentAccount(paymentAccountID) {
  const {json: paymentAccount} = await api.get(
    `/company/payment/${paymentAccountID}/`,
  )

  setPaymentAccount(paymentAccount)

  return paymentAccount
}

export async function updatePaymentAccount(paymentAccountID, params) {
  const {json: paymentAccount} = await api.put(
    `/company/payment/${paymentAccountID}/`,
    params,
  )

  setPaymentAccount(paymentAccount)

  return paymentAccount
}
