import {useRef, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import {HOT_KEY_CODES} from '../constants/HotKeys.js'

export default function BarcodeScannerListener({
  minLength,
  capturePaste,
  allowHotKeys,
  onScan,
}) {
  minLength = minLength !== undefined ? minLength : 5

  const ref = useRef({value: ''})

  // We want the current onScan function but we also don't want to rebuild our callbacks when it changes
  ref.current.onScan = onScan

  const handleInput = useCallback(
    debounce((value) => {
      value = value.trim()

      if (
        value.length >= minLength &&
        (allowHotKeys || !HOT_KEY_CODES.includes(value))
      ) {
        ref.current.onScan(value)
      }

      ref.current.value = ''
    }, 100),
    [minLength, allowHotKeys],
  ) // 30ms is too short for shitty blutooth scanners, 200ms is too long for normal typing

  useEffect(() => {
    function handleKeyPress(event) {
      // iOS Chrome didn't give us a key value for some reason
      if (!event.key) {
        return
      }

      // There is usually an 'Enter' key that is sent during scanning.
      // We don't want it to "press" a focused button, so we prevent the default behavior.
      // This makes it so that pressing the Enter key does nothing when using this barcode
      // scanner component.
      if (event.key === 'Enter' && ref.current.value) {
        event.preventDefault()
      }

      // We want ("a", "b", "1", etc). Other key values might be "Enter"
      if (event.key.length === 1) {
        ref.current.value += event.key

        handleInput(ref.current.value)
      }
    }

    document.addEventListener('keypress', handleKeyPress)

    return () => document.removeEventListener('keypress', handleKeyPress)
  }, [handleInput])

  // I don't think any barcode scanners actually use paste, but it's
  // useful for debug/testing without annoying everyone in the office with
  // beeps
  useEffect(() => {
    if (!capturePaste) {
      return
    }

    function handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData

      if (!clipboardData) {
        return
      }

      handleInput(clipboardData.getData('text'))
    }

    document.addEventListener('paste', handlePaste)

    return () => document.removeEventListener('paste', handlePaste)
  }, [capturePaste, handleInput])

  return null
}

BarcodeScannerListener.propTypes = {
  minLength: PropTypes.number,
  capturePaste: PropTypes.bool,
  allowHotKeys: PropTypes.bool,
  onScan: PropTypes.func.isRequired,
}
