import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

export default function ButtonSecondary({className, title, onClick}) {
  return (
    <button
      className={classNames(
        'btn btn--secondary btn--md inline-block',
        className,
      )}
      type="button"
      onClick={onClick}
    >
      {title}
    </button>
  )
}

ButtonSecondary.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

ButtonSecondary.defaultProps = {
  className: null,
}
