import PropTypes from 'prop-types'
import React, {useEffect} from 'react'

export default function MessageToast({options, closeToast}) {
  useEffect(() => {
    if (options.mustConfirm) {
      return
    }

    const timeout = setTimeout(() => closeToast(), 5000)

    return () => clearTimeout(timeout)
  }, [options.message])

  return (
    <div className="alert__inner-wrap--global">
      {options.header && <header>{options.header}</header>}
      <strong>{options.message}</strong>
    </div>
  )
}

MessageToast.propTypes = {
  options: PropTypes.shape({
    message: PropTypes.string,
    header: PropTypes.string,
    mustConfirm: PropTypes.bool,
  }).isRequired,
  closeToast: PropTypes.func.isRequired,
}
