import userflow from 'userflow.js'
import {IS_DEV_ENV, IS_PROD_ENV} from '../constants/index.js'

const DEBUG = false
let useUserflow = false

export default {
  init(user, company) {
    if (
      !((DEBUG || IS_PROD_ENV || IS_DEV_ENV) && company.features.use_userflow)
    ) {
      return
    }

    useUserflow = true

    userflow.init(
      IS_PROD_ENV
        ? 'ct_fkarhldw5vfvtp4v4gybhoqxoa'
        : 'ct_msc3effutreghcsu5lbjivdase',
    )

    userflow.identify(user.id, {
      name: user.name,
      email: user.email,
      date_joined: user.date_joined,
    })

    // Groups cost money so don't use them
    userflow.updateUser({
      company_id: {set: company.id, data_type: 'string'},
      company_name: company.name,
      company_created: company.created,
    })
    // userflow.group(company.id, {
    //   name: company.name,
    //   created: company.created,
    // })
  },
  updateGroup(...args) {
    if (!useUserflow) {
      return
    }

    // Groups cost money so don't use them
    userflow.updateUser(...args)
    // userflow.updateGroup(...args)
  },
}
