import './common/libs.js'
import analytics from './common/analytics/index.js'
import datadogRum from './common/analytics/datadogRum.js'

import '../sass/login.scss'

if (document && document.querySelector) {
  const form = document.querySelector('.login-form')

  if (form) {
    let isSubmitting = false
    const submitButton = form.querySelector('[type="submit"]')

    form.addEventListener('submit', function (event) {
      if (isSubmitting) {
        event.preventDefault()

        return
      }

      isSubmitting = true

      submitButton.disabled = true
      submitButton.classList.add('submitting')
    })
  }

  analytics.init()

  datadogRum.startView({name: '/login'})
}
