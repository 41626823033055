import uniq from 'lodash/uniq'

export function ensureArray(value) {
  if (value === '' || value === null || value === undefined) {
    return []
  }

  if (!Array.isArray(value)) {
    return [value]
  }

  return value
}

export function ensureArrayOfNumbers(value) {
  return ensureArray(value).map((value) => Number(value))
}

export function ensureBoolean(value) {
  return value === true || value === 'true'
}

export function ensureTroolean(value) {
  return ensureBoolean(value)
    ? true
    : value === false || value === 'false'
    ? false
    : null
}

export function booleanStringCase(value) {
  return value === true ? 'true' : value === false ? 'false' : undefined
}

export function nullParamCast(value) {
  return value !== null ? value : undefined
}

export function ensureArrayOrUndefined(value) {
  value = value === '' || value === null || value === undefined ? [] : value

  if (!Array.isArray(value)) {
    value = [value]
  }

  if (value.length === 0) {
    value = undefined
  } else {
    value = uniq(value)
  }

  return value
}

export function ensureNumber(value) {
  value = Number(value)

  return isNaN(value) ? null : value
}
