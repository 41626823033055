import api from './api.js'
import apiverson from './apiverson.js'

export async function fetchAll(get, url, type, params) {
  let items = []
  let expectedCount = Infinity
  const query = {
    limit: 100,
    offset: 0,
    ...params,
  }

  while (items.length < expectedCount) {
    const res = await get(url, query)

    items = items.concat(res.json[type])

    expectedCount = res.json.count
    query.limit = res.json.limit
    query.offset = res.json.offset + res.json.limit
  }

  return items
}

export function fetchAllAPI(...args) {
  return fetchAll(api.get, ...args)
}

export function fetchAllApiverson(...args) {
  return fetchAll(apiverson.get, ...args)
}
