import PropTypes from 'prop-types'
import React from 'react'

export default function Footer({children}) {
  return (
    <div className="modal__footer">
      <div className="wrap--row">
        <div className="medium-12 columns">{children}</div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}
