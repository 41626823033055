export const SHIP_APP_ESSENTIALS = 'ship_essentials'
export const SHIP_APP_ADVANCED = 'ship_advanced'
export const SHIP_APP_PREMIUM = 'ship_premium'
export const DROPSHIP_APP_ADVANCED = 'dropship_advanced'
export const DROPSHIP_APP_PREMIUM = 'dropship_premium'
export const INVENTORY_APP_ADVANCED = 'inventory_advanced'
export const INVENTORY_APP_PREMIUM = 'inventory_premium'

export const APP_PRICES = {
  [SHIP_APP_ESSENTIALS]: 0,
  [SHIP_APP_ADVANCED]: 59,
  [SHIP_APP_PREMIUM]: 149,
  [DROPSHIP_APP_ADVANCED]: 299,
  [DROPSHIP_APP_PREMIUM]: Infinity,
  [INVENTORY_APP_ADVANCED]: 349,
  [INVENTORY_APP_PREMIUM]: 499,
}

export const APP_DISPLAY = {
  [SHIP_APP_ESSENTIALS]: 'Essentials',
  [SHIP_APP_ADVANCED]: 'Advanced',
  [SHIP_APP_PREMIUM]: 'Premium',
  [DROPSHIP_APP_ADVANCED]: 'Advanced',
  [DROPSHIP_APP_PREMIUM]: 'Premium',
  [INVENTORY_APP_ADVANCED]: 'Advanced',
  [INVENTORY_APP_PREMIUM]: 'Premium',
}

export const APP_LONG_DISPLAY = {
  [SHIP_APP_ESSENTIALS]: 'Ship Essentials',
  [SHIP_APP_ADVANCED]: 'Ship Advanced',
  [SHIP_APP_PREMIUM]: 'Ship Premium',
  [DROPSHIP_APP_ADVANCED]: 'Dropship Advanced',
  [DROPSHIP_APP_PREMIUM]: 'Dropship Premium',
  [INVENTORY_APP_ADVANCED]: 'Inventory Advanced',
  [INVENTORY_APP_PREMIUM]: 'Inventory Premium',
}
