import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {closeModal} from '../../../../redux/actions/ui/index.js'

function ButtonCancel({className, ...props}) {
  return (
    <button
      className={classNames(
        'btn btn--secondary btn--md inline-block',
        className,
      )}
      type="button"
      onClick={() => props.closeModal()}
    >
      Cancel
    </button>
  )
}

ButtonCancel.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

ButtonCancel.defaultProps = {
  className: null,
}

const mapDispatchToProps = {
  closeModal,
}

export default connect(null, mapDispatchToProps)(ButtonCancel)
